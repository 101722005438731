<template>
  <v-card elevation="5" max-width="1070" class="import-group mx-lg-auto mx-4 mx-md-15 mt-5 py-sm-10 py-5" id="import">
    <div class="text-uppercase text-h5 font-weight-bold px-sm-10 px-4">Import</div>
    <div v-if="fileLoad != 'done'" class="px-sm-10 px-4">
      <div class="opasity--text mt-2">You can import contacts fast by uploading</div>
      <div class="opasity--text">CSV with contacts data</div>
      <div class="d-flex flex-wrap justify-space-between mt-3">
        <div v-if="!file" @click="upload" class="link file mx-md-0 mx-auto" style="border: 2px dashed #d0d0d0">
          <div class="mr-5"><v-icon color="input">mdi-cloud-upload mdi-48px</v-icon></div>
          <div>
            <div class="font-weight-medium">Drag and drop your file or</div>
            <div class="font-weight-bold input--text text-decoration-underline">Select a file</div>
          </div>
        </div>
        <div v-else-if="fileLoad == 'start'" class="file flex-column mx-md-0 mx-auto" style="border: 2px dashed #d0d0d0">
          <div class="d-flex align-center">
            <div class="mr-5">
              <div class="font-weight-medium">Uploading...</div>
              <div class="f14 font-weight-medium opasity--text">{{ file.name }}</div>
            </div>
            <div class="d-flex">
              <span class="f14 font-weight-bold opasity--text">{{ size }}</span>
              <v-icon @click="file = ''" color="opasity" class="link">mdi-close-circle mdi-18px</v-icon>
            </div>
          </div>
          <v-progress-linear indeterminate color="input"></v-progress-linear>
        </div>
        <div v-else-if="fileLoad == 'end'" class="file mx-md-0 mx-auto" style="background: #fafbff">
          <div class="mr-5"><img src="@/assets/img/file.svg" /></div>
          <div>
            <div class="font-weight-medium">{{ file.name }}</div>
            <div class="font-weight-bold opasity--text">{{ size }}</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-icon @click="file = ''" color="#D6DEFF" class="link">mdi-close-circle mdi-36px</v-icon>
          </div>
        </div>
        <div v-else-if="fileLoad == 'error'" @click="upload" class="link file mx-md-0 mx-auto" style="border: 2px dashed #d0d0d0">
          <div class="mr-5"><v-icon color="input">mdi-cloud-upload mdi-48px</v-icon></div>
          <div>
            <div class="font-weight-medium error--text">Error. Please, try again</div>
            <div class="font-weight-bold input--text text-decoration-underline">Select a file</div>
          </div>
        </div>
        <div class="mx-md-0 mx-auto mt-md-0 mt-2">
          <v-btn :disabled="fileLoad != 'end'" @click="importPre" width="300" height="48" elevation="0" color="primary">Upload</v-btn>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="px-sm-10 px-4">
        <div class="d-flex justify-space-between mt-2">
          <div>
            <div class="opasity--text">Please, check the Contact Field names to the import</div>
            <div class="opasity--text">sheet Column name</div>
          </div>
          <div class="mx-md-0 mx-auto mt-md-0 mt-2">
            <v-btn @click="importFile" width="300" height="48" elevation="0" color="primary">Import</v-btn>
          </div>
        </div>
        <v-radio-group v-model="radios" mandatory class="mt-10">
          <template v-slot:label>
            <div class="text-h6">Duplicate phone numbers:</div>
          </template>
          <v-radio label="Update" value="update" color="success"></v-radio>
          <v-radio label="Ignore" value="ignore" color="success"></v-radio>
        </v-radio-group>
        <div class="text-h6 mt-7">Preview</div>
        <div class="opasity--text">Please, check the Contact Field names to the import sheet Column name</div>
      </div>
      <v-data-table class="p-table" hide-default-footer hide-default-header :headers="headersPreview" :items="itemPreview">
        <template v-slot:header>
          <thead>
            <tr>
              <th class="py-3" v-for="(item, i) in headersPreview" :key="i">
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="selectItems"
                  v-model="value[i]"
                  hide-details
                  dark
                  filled
                  outlined
                  dense
                  class="input"
                >
                </v-select>
              </th>
            </tr>
          </thead>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      file: '',
      size: '',
      fileLoad: '',
      radios: 'update',
      headersPreview: [],
      itemPreview: [],
      value: [],
      selectItems: [],
      error: null,
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'invalidFile') {
        this.$notify({ message: 'Sorry, your file invalid', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    upload() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.csv');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.addEventListener('loadstart', () => {
            self.fileLoad = 'start';
          });
          reader.addEventListener('loadend', () => {
            self.fileLoad = 'end';
          });
          reader.addEventListener('error', () => {
            self.fileLoad = 'error';
          });
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async importPre() {
      const formData = new FormData();
      formData.append('contacts', this.file);
      await this.$store
        .dispatch('importPreContants', formData)
        .then(() => {
          this.fileLoad = 'done';
        })
        .catch((e) => {
          this.fileLoad = 'error';
          if (e.response.status == 400) {
            this.notifi('invalidFile');
          }
        });
    },
    async importFile() {
      const formData = new FormData();
      formData.append('contacts', this.file);
      formData.append('variables', this.value);
      formData.append('duplicates', this.radios);
      await this.$store
        .dispatch('importContants', formData)
        .then(() => {
          this.$router.push(`/groups/${this.$route.params.groupId}/progress-import`);
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
        });
    },
    renameKeys(arrayObject, newKeys, index = false) {
      const newArray = [];
      arrayObject.forEach((obj, item) => {
        const keyValues = Object.keys(obj).map((key, i) => {
          return { [newKeys[i] || key]: obj[key] };
        });
        const id = index ? { ID: item } : {};
        newArray.push(Object.assign(id, ...keyValues));
      });
      return newArray;
    },
  },
  watch: {
    variableItems() {
      this.selectItems = this.variableItems.slice();
      this.selectItems.push({ title: 'Ignore', id: 'null' });
    },
    file() {
      if (this.file.size / (1024 * 1024 * 1024) > 1) {
        this.size = (this.file.size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      } else if (this.file.size / (1024 * 1024) > 1) {
        this.size = (this.file.size / (1024 * 1024)).toFixed(2) + ' MB';
      } else if (this.file.size / 1024 > 1) {
        this.size = (this.file.size / 1024).toFixed(2) + ' KB';
      } else {
        this.size = this.file.size + ' B';
      }
    },
    preview() {
      this.headersPreview = [];
      this.itemPreview = [];
      const value = [];
      for (var key in this.preview.data[0]) {
        value.push(this.preview.data[0][key].toLowerCase().split(' ').join('_'));
        this.headersPreview.push({
          text: this.preview.data[0][key],
          value: this.preview.data[0][key].toLowerCase().split(' ').join('_'),
          sortable: false,
        });
      }
      const arr = [];
      for (var i = 0; i < this.preview.data.length; i++) {
        arr.push({
          ...this.preview.data[i],
        });
      }
      this.itemPreview = this.renameKeys(arr, value);
      this.value = [];
      for (var key in this.preview.columns) {
        this.value.push(!this.preview.columns[key] ? 'null' : this.preview.columns[key].id);
      }
    },
  },
  computed: {
    variableItems() {
      return this.$store.getters.groupsVars;
    },
    preview() {
      return this.$store.getters.importPreview;
    },
  },
};
</script>

<style>
.import-group tbody tr {
  height: 52px;
}
.import-group .file {
  padding: 0 24px;
  width: 370px;
  height: 87px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
</style>
