<template>
  <v-row class="group">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between align-baseline">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/groups" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ data.name }}
          </router-link>
        </div>
        <v-btn @click="deleteGroup = !deleteGroup" elevation="0" width="184" height="44" color="error">
          <v-icon class="mr-3">mdi-delete</v-icon> Delete group
        </v-btn>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="text-uppercase text-h5 font-weight-bold">General</div>
          <div class="px-sm-10 py-sm-5">
            <div class="mx-auto group-input">
              <v-text-field v-model="data.name" label="Group Name" filled outlined dense color="input"></v-text-field>
              <v-text-field v-model="data.description" label="Description" filled outlined dense color="input"></v-text-field>
            </div>
          </div>
          <div class="text-center">
            <v-btn @click="updateItem" elevation="0" color="primary" width="184" height="48">Save</v-btn>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold pt-sm-10 px-sm-10 px-4 pt-5 mb-2">Available variables</div>
          <v-divider></v-divider>
          <v-data-table hide-default-footer fixed-header :headers="headers" :items="variableItems" class="p-table">
            <template v-slot:top>
              <v-dialog v-model="itemVars" max-width="536">
                <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
                  <div class="text-h5 font-weight-bold mb-5">Edit variable</div>
                  <v-text-field v-model="editedItem.default" label="Default" filled outlined dense color="input"></v-text-field>
                  <div class="mt-5 d-flex flex-column align-center">
                    <v-btn width="184" height="48" color="primary" @click="updeteItemVars">Update</v-btn>
                    <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="itemVars = !itemVars">Close</v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <v-dialog v-model="deleteVars" max-width="536">
                <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
                  <div class="text-h5 font-weight-bold">Remove variable</div>
                  <div class="mt-3 opasity--text">Do you really want to remove {{ editedItem.name }}?</div>
                  <div class="mt-7">
                    <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
                    <v-btn width="120" height="48" color="secondary" @click="deleteItemVars">yes</v-btn>
                  </div>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.name" height="52" class="link">
                  <td>{{ item.title }}</td>
                  <td class="text-capitalize">{{ item.type }}</td>
                  <td class="d-sm-table-cell d-none">{{ item.default_value_string || item.default_value_number }}</td>
                  <td class="text-right">
                    <v-btn v-if="item.type != 'phone'" class="rounded" text icon tile elevation="0" @click.stop="openItem(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <span v-if="item.can_delete || item.type != 'phone'" class="divider-vertical"></span>
                    <v-btn
                      v-if="item.can_delete || item.type != 'phone'"
                      class="rounded"
                      text
                      icon
                      tile
                      elevation="0"
                      @click.stop="openDelete(item)"
                    >
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <div class="mx-7">
            <v-btn class="my-2" height="48" text color="input" @click="addVarsModal = !addVarsModal">
              <v-icon class="input rounded-circle btn-icon" color="white" left>mdi-plus mdi-24px</v-icon>
              Add variable
            </v-btn>
          </div>
        </v-card>
        <ImportFile />
      </div>
      <v-dialog v-model="deleteGroup" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Delete Group</div>
          <div class="mt-3 opasity--text">Do you really want to delete {{ data.name }}?</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="deleteGroup = !deleteGroup">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="deleteItem">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addVarsModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add new variable</div>
          <v-text-field
            v-model="variables.name"
            label="Name"
            :error-messages="nameErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <v-select
            v-model="variables.type"
            :items="['String', 'Number']"
            label="Type"
            :error-messages="typeErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-select>
          <v-text-field
            v-model="variables.default"
            label="Default value"
            :error-messages="defaultErrors"
            filled
            outlined
            dense
            required
            color="input"
          ></v-text-field>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="newVariables">Add</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="addVarsModal = !addVarsModal">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ImportFile from '@/components/group/ImportFile';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    ImportFile,
  },
  validations: {
    variables: {
      name: { required },
      type: { required },
    },
  },
  data() {
    return {
      load: true,
      deleteGroup: false,
      headers: [
        { text: 'Name', sortable: false },
        { text: 'Type', sortable: false },
        { text: 'Default Name', class: 'd-sm-table-cell d-none', sortable: false },
        { text: 'Actions', align: 'end', sortable: false },
      ],
      variables: {
        name: '',
        type: '',
        default: '',
      },
      editedIndex: -1,
      editedItem: {},
      addVarsModal: false,
      itemVars: false,
      deleteVars: false,
      error: null,
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.getVariables();
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: `Group ${this.data.name} updete`, horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'deleteVars') {
        this.$notify({ message: 'Variable  delete', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'delete') {
        this.$notify({ message: 'Group delete', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openItem(item) {
      this.editedIndex = this.variableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.default = item.default_value_number || item.default_value_string;
      this.itemVars = true;
    },
    openDelete(item) {
      this.editedIndex = this.variableItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.deleteVars = true;
    },
    closeDialog() {
      this.itemVars = false;
      this.deleteVars = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    },
    async updeteItemVars() {
      const data = new Object();
      this.editedItem.type == 'number'
        ? (data.default_value_number = Number(this.editedItem.default) || 'false')
        : (data.default_value_string = this.editedItem.default);
      const id = this.editedItem.id;
      await this.$store.dispatch('updeteGroupsItemVars', { data, id }).then(() => {
        this.getVariables();
        this.notifi('deleteVars');
        this.itemVars = false;
      });
    },
    async deleteItemVars() {
      await this.$store.dispatch('deleteGroupsItemVars', this.editedItem.id).then(() => {
        this.getVariables();
        this.notifi('deleteVars');
        this.deleteVars = false;
      });
    },
    async getData() {
      await this.$store.dispatch('getGroup').finally(() => {
        this.load = false;
      });
    },
    async updateItem() {
      const data = new Object();
      data.name = this.data.name;
      data.description = this.data.description;
      const id = this.$route.params.groupId;
      await this.$store.dispatch('updateGroups', { data, id }).then(() => {
        this.updateGroup = !this.updateGroup;
        this.notifi('update');
      });
    },
    async deleteItem() {
      await this.$store.dispatch('deleteGroups', this.$route.params.groupId).then(() => {
        this.notifi('delete');
        this.$router.push('/groups');
      });
    },
    async getVariables() {
      await this.$store.dispatch('getGroupsVars', this.$route.params.groupId);
    },
    async newVariables() {
      this.error = null;
      this.$v.variables.$touch();
      if (!this.$v.variables.$invalid) {
        const data = new Object();
        data.title = this.variables.name;
        data.type = this.variables.type.toLowerCase();
        this.variables.type == 'Number'
          ? (data.default_value_number = Number(this.variables.default) || 'false')
          : (data.default_value_string = this.variables.default);
        await this.$store
          .dispatch('newGroupsVars', data)
          .then(() => {
            this.addVarsModal = false;
            this.getVariables();
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
          });
      }
    },
  },
  watch: {
    data() {
      if (this.data.import_progress) {
        if (this.data.import_progress.status != 'finished') {
          this.$router.push(`/groups/${this.data.id}/progress-import`);
        }
      }
    },
    itemVars(val) {
      val || this.closeDialog();
    },
    deleteVars(val) {
      val || this.closeDialog();
    },
    addVarsModal() {
      this.$v.variables.$reset();
      this.variables = {
        name: '',
        type: '',
        default: '',
      };
    },
  },
  computed: {
    data() {
      return this.$store.getters.group;
    },
    variableItems() {
      return this.$store.getters.groupsVars;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.variables.name.$dirty) {
        return errors;
      }
      !this.$v.variables.name.required && errors.push('Name is required');
      this.error == 'title__invalid' && errors.push('Sorry, Name invalid');
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.variables.type.$dirty) {
        return errors;
      }
      !this.$v.variables.type.required && errors.push('Type is required');
      return errors;
    },
    defaultErrors() {
      const errors = [];
      this.error == 'default_value_string__invalid' && errors.push('Sorry, Default value invalid');
      this.error == 'default_value_number__invalid' && errors.push('Sorry, Default value invalid');
      this.error == 'body_json__invalid' && errors.push('Sorry, Default value invalid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setGroup', {});
    this.$store.dispatch('setImportPreContants', {});
    this.$store.dispatch('setImportProgContants', {});
    this.$store.dispatch('setGroupsVars', []);
  },
};
</script>

<style scoped>
.group-input {
  max-width: 600px;
  width: 100%;
}
.btn-icon {
  width: 32px !important;
  height: 32px !important;
}
</style>
